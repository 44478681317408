import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import scalingSize from '$utils/scalingSize';
import getImageData from '$utils/getImageData';
import { Wrap } from '$components/Wraps';
import Markdown from '$components/Markdown';
import { BlockCaseImageFigureProps } from './types';
import KeyFigureFigure from './KeyFigureFigure';
import KeyFigureImage from './KeyFigureImage';

const KeyFigure = styled(Wrap)`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  align-items: end;
  ${scalingSize('margin-top', 32, 112)};
  ${scalingSize('margin-bottom', 24, 80)};
`;

const BlockCaseImageFigure: React.FC<BlockCaseImageFigureProps> = ({ image, content, figure, figureDescription }) => (
  <KeyFigure>
    <KeyFigureImage>
      <GatsbyImage image={getImageData(image.imageFile)} alt={image.alternativeText} />
    </KeyFigureImage>
    <Markdown indented content={content} />
    <KeyFigureFigure figure={figure} figureDescription={figureDescription} />
  </KeyFigure>
);

export default BlockCaseImageFigure;
