import React from 'react';
import styled from 'styled-components';
import { Color } from '$utils/Color';
import scalingSize from '$utils/scalingSize';
import { KeyFigureFigureProps } from './types';

const Heading2 = styled.h2`
  color: var(--color-slate);
  ${scalingSize('font-size', 32, 56)};
  line-height: 1.14;
  letter-spacing: -0.03em;
`;

const Paragraph = styled.p`
  line-height: 1.75;
  margin: 1em 0 2em;
  padding-left: 0.6rem;
  color: var(--color-body-text);
`;

const Ellipse = (color: string) =>
  `data:image/svg+xml;utf8,${encodeURIComponent(
    `<svg viewBox="0 0 560 560" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M560 280C560 434.64 434.64 560 280 560C125.36 560 0 434.64 0 280C0 125.36 125.36 0 280 0C434.64 0 560 125.36 560 280ZM123.2 280C123.2 366.598 193.402 436.8 280 436.8C366.598 436.8 436.8 366.598 436.8 280C436.8 193.402 366.598 123.2 280 123.2C193.402 123.2 123.2 193.402 123.2 280Z" fill="${color}"/></svg>`
  )}`;

const StyledKeyFigureFigure = styled.div`
  grid-row: 1;
  grid-column: 13 / 21;
  position: relative;
  padding-bottom: 100%;

  > div {
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 25%;
    text-align: center;
    display: none;

    @media screen and (min-width: 769px) {
      display: flex;
    }

    ${Heading2},
    ${Paragraph} {
      position: relative;
      margin: 0;
      color: var(--color-iris);
    }

    ${Heading2} {
      margin: 0 0 1rem;
    }

    ${Paragraph} {
      font-size: 0.875rem;
    }

    &:before {
      content: '';
      position: absolute;
      background-image: url('${Ellipse(Color.champ4)}');
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 999em;
    }
  }
`;

const KeyFigureFigure: React.FC<KeyFigureFigureProps> = ({ figure, figureDescription }) => (
  <StyledKeyFigureFigure>
    <div>
      <Heading2>{figure}</Heading2>
      <Paragraph>{figureDescription}</Paragraph>
    </div>
  </StyledKeyFigureFigure>
);

export default KeyFigureFigure;
